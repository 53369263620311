import './artist.scss';
import { useParams } from 'react-router-dom';
import { data } from '../../shared/data/cards';
import Navigation from '../../components/navigation/naviagation';
import Cards from '../../components/cards/cards';
import { artists } from '../../shared/data/artistsBio';

export default function Artist() {
  const { id } = useParams();
  const artistName = id?.replace('_', ' ');
  const artistWork = data.filter((el) => el?.author === artistName);

  const artistInfo = artists.find((artist) => artist.name === artistName);

  return (
    <>
      <Navigation />
      <div className="artist">
        <div className="artist-info">
          <div className="title artist-name">{artistName}</div>
          {artistInfo?.bio && (
            <div className="text artist-bio">{artistInfo?.bio}</div>
          )}
          {artistInfo?.url && (
            <a
              href={artistInfo.url}
              target="_blank"
              className="text artist-link"
            >
              {artistInfo.url.replace('https://', '')}
            </a>
          )}
        </div>
        <Cards data={artistWork} />
      </div>
    </>
  );
}
