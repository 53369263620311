import { createSlice } from '@reduxjs/toolkit'

export const order = createSlice({
  name: 'order',
  initialState: {
    order: [] as any,
    orderStatus: '',
  },
  reducers: {
    addItem: (state, item) => {
      state.order.push(item);
    },
    removeItem: (state, item) => {
      return {
        ...state,
        order: [
          ...state.order.filter((el: any) => el.payload.id !== item.payload.id)
        ]
      }
    },
    increaseItem: (state, item) => {
      return {
        ...state,
        order: [
          ...state.order.map((el: any) => {
            if (el.payload.id === item.payload.id) {
              return {
                payload: {
                  ...el.payload,
                  quantity: el.payload.quantity + 1
                }
              }
            }
            return el;
          })
        ]
      }
    },
    decreaseItem: (state, item) => {
      return {
        ...state,
        order: [
          ...state.order.map((el: any) => {
            if (el.payload.id === item.payload.id) {
              return {
                payload: {
                  ...el.payload,
                  quantity: el.payload.quantity - 1 ? el.payload.quantity - 1 : el.payload.quantity
                }
              }
            }
            return el;
          })
        ]
      }
    },
    clearOrder: (state) => {
      return {
        ...state,
        order: []
      }
    },
    updateOrderStatus: (state, status) => {
      return {
        ...state,
        orderStatus: status.payload
      }
    }
  },
})

export const { addItem, removeItem, increaseItem, decreaseItem, clearOrder, updateOrderStatus } = order.actions

export default order.reducer
