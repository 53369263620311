'use client';

import { useState } from 'react';
import './artists-list.scss';
import { data } from '../../shared/data/cards';
import { Link } from 'react-router-dom';

export default function ArtistsList() {
  const [image, setImage] = useState({} as any);
  const [artistName, setArtist] = useState({} as any);

  const uniqueArtists = data.filter(
    (el, index) => data.findIndex((obj) => obj.author === el.author) === index
  );

  const setHovered = (artist: any) => {
    const artists = data.filter((el) => el.author === artist.author);
    setArtist(artists[0].author);
    setImage(artists[Math.floor(Math.random() * artists.length)]);
  };

  const artists = uniqueArtists.map((artist) => {
    return (
      <Link
        to={`/artist/${artist.author.replace(' ', '_')}`}
        className={`artists-list-item ${
          artist.author === artistName ? 'active' : ''
        }`}
        key={artist.author}
        onMouseEnter={() => setHovered(artist)}
      >
        {artist.author}
      </Link>
    );
  });

  return (
    <div className="artists-list">
      <div>{artists}</div>

      <div className="artists-list-img">
        <Link to={`/art/${image.id}`}>
          {image.sizes && (
            <img src={'/img/' + image.sizes[0].fileName} alt="" />
          )}
        </Link>
      </div>
    </div>
  );
}
