import './main-info.scss';

import { useEffect, useState } from 'react';

import logo from '../../shared/data/svg/logo.svg';
import { data } from '../../shared/data/mainScreenPhotos';

export default function MainInfo() {

  const getRandomImg = () => {
    return data[Math.floor(Math.random() * data.length)];
  };

  const [img, setImg] = useState(getRandomImg());

  useEffect(() => {
    const timer = setInterval(() => {
      setImg(getRandomImg());
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="main-info">
      <div className="title-box">
        <div
          className="title-box-img"
          style={{ backgroundImage: `url(/img/${img})` }}
        ></div>
        <div className="title-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="description">
        {/* <div className="description-title title">
          "A Spectr” is an online print sale by Kyiv Angels charity foundation.
        </div> */}
        {/* <div className="description-block text">
          First series of the project - “Fantasy on open theme" - features works of 20 contemporary Ukrainian photographers. Іt is a unique gaze into the self, the world (either real or imaginary), and the struggle for beauty and harmony in it. These series are not about the war, which is our reality here and now, not about pain or tears, nor sacrifice, but about the future and ability to shape it together.  Within this project, everyone has the opportunity to contribute to the important cause.
        </div> */}
        {/* <div className="description-block text">
          We are holding a print sale to support the "Rebuild" charity project. Each purchased artwork is your contribution to the reconstruction of war-damaged housing in the Dergachiv community of Kharkiv region.
        </div> */}
        <div className="description-block text">
          Aspectr is collaborative gallery platform where we sell works of artists to raise money for our defenders.
          We are working in two formats: selling prints and making auction of original works.
          Our first goal is to purchase components for fpv drones for Rarog and Modul.
        </div>
      </div>
    </div>
  );
}
