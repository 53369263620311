import { useEffect, useState } from 'react';
import './cards.scss';
import { Link } from 'react-router-dom';
import { Art } from '../../shared/data/cards';
import Masonry from 'react-responsive-masonry';

export default function Cards({ data }: { data: Art[] }) {
  const [columns, setColumns] = useState(1);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width > 980 && columns < 3) {
        setColumns(3);
      } else if (width <= 980 && columns > 2) {
        setColumns(1);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [columns]);

  data.sort((a, b) => (a.order > b.order ? 1 : -1));

  const setLessColumns = () => {
    if (window.innerWidth > 980) {
      setColumns(3);
    } else {
      setColumns(1);
    }
  };
  const setMoreColumns = () => {
    if (window.innerWidth > 980) {
      setColumns(4);
    } else {
      setColumns(2);
    }
  };

  const cardList = () => {
    return (
      <>
        <div className="card-grid">
          <span>Grid:</span>
          <input
            onClick={() => setLessColumns()}
            type="radio"
            id="radio-less"
            checked={columns === 1 || columns === 3}
          />
          <label htmlFor="radio-less">less</label>
          <input
            onClick={() => setMoreColumns()}
            type="radio"
            id="radio-more"
            checked={columns === 2 || columns === 4}
          />
          <label htmlFor="radio-more">more</label>
        </div>
        <Masonry columnsCount={columns} gutter={'10px'}>
          {data.map((card) => {
            return (
              <div className="card" key={card.id}>
                <Link to={`/art/${card.id}`}>
                  <img
                    src={'/img/' + card?.sizes?.[0]?.fileName}
                    alt={card?.work}
                    loading="lazy"
                    style={{
                      display: 'block',
                      width: '100%',
                    }}
                  />
                  <div className="text-bold">{card.work}</div>
                </Link>
                <Link
                  to={`/artist/${card.author.replace(' ', '_')}`}
                  className="card-artist text-secondary"
                >
                  {card.author}
                </Link>
                <div className="card-price text-bold">
                  From {card?.sizes?.[0]?.price} €
                </div>
              </div>
            );
          })}
        </Masonry>
      </>
    );
  };

  return <div className="cards">{cardList()}</div>;
}
