import { useDispatch, useSelector } from 'react-redux';
import Navigation from '../../components/navigation/naviagation';
import './bag.scss';
import { useForm } from 'react-hook-form';
import {
  clearOrder,
  decreaseItem,
  increaseItem,
  removeItem,
  updateOrderStatus,
} from '../../store/order';
import { useEffect } from 'react';

export default function Bag() {
  const state = useSelector((state: any) => state.order);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (userData: any) => {
    const response = await fetch('https://sheetdb.io/api/v1/ph1ph4nagziee', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userData: JSON.stringify(userData),
        orderData: JSON.stringify(state.order),
      }),
    });
    if (response.status === 201) {
      dispatch(clearOrder());
      dispatch(updateOrderStatus('SUCCESS'));
    }
  };

  useEffect(() => {
    if (state.orderStatus === 'SUCCESS') {
      dispatch(updateOrderStatus(''));
    }
  }, []);

  return (
    <div className="bag">
      <Navigation />
      <div className="bag-content">
        <div className="bag-content_wrapper">
          <h2 className="bag-content_title title">Shopping bag</h2>
          {state.order.length ? (
            state.order.map((el: any) => {
              const item = el.payload;
              return (
                <div key={item.id} className="bag-content_item">
                  <div className="bag-content_img">
                    <img
                      src={'/img/' + item.sizeData.fileName}
                      alt={item.work}
                    />
                  </div>
                  <div className="bag-content_data text-bold">
                    <p>€ {item.sizeData.price}</p>
                    <p>{item.work}</p>
                    <p className="bag-content_author">{item.author}</p>
                    <p className='text-secondary'>Size:</p>
                    <p className="bag-content_size">{item.sizeData.size}</p>
                    <p className='text-secondary'>Quantity:</p>
                    <p>
                      <span
                        className="bag-content_decrease"
                        onClick={() => dispatch(decreaseItem(item))}
                      >
                        -{' '}
                      </span>
                      {item.quantity}
                      <span
                        className="bag-content_increase"
                        onClick={() => dispatch(increaseItem(item))}
                      >
                        {' '}
                        +
                      </span>
                    </p>
                    <p
                      onClick={() => dispatch(removeItem(item))}
                      className="bag-content_remove"
                    >
                      X
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div>Empty</div>
          )}
        </div>
        <div className="bag-content_wrapper">
          {state.order.length > 0 && (
            <>
              <div className="bag-content_total title">
                <h2>Total</h2>
                <h2>
                  €
                  {state.order.reduce(
                    (a: any, b: any) =>
                      a + b.payload.sizeData.price * b.payload.quantity,
                    0
                  )}
                </h2>
              </div>
              <h2 className="bag-content_title title">Shipping information</h2>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="bag-content_form"
                >
                  <input
                    className={errors.firstName && 'error'}
                    placeholder={'First name*'}
                    {...register('firstName', { required: true })}
                  />
                  <input
                    className={errors.lastName && 'error'}
                    placeholder={'Last name*'}
                    {...register('lastName', { required: true })}
                  />
                  <input
                    className={errors.country && 'error'}
                    placeholder={'Country*'}
                    {...register('country', { required: true })}
                  />
                  <input
                    className={`bag-content_form-street ${
                      errors.street && 'error'
                    }`}
                    placeholder={'Street address*'}
                    {...register('street', { required: true })}
                  />
                  <input
                    placeholder={'Appartment, suite, unit, etc.'}
                    {...register('appartment')}
                  />
                  <input
                    className={errors.zip && 'error'}
                    placeholder={'Postcode / Zip*'}
                    {...register('zip', { required: true })}
                  />
                  <input
                    className={errors.city && 'error'}
                    placeholder={'Town / City*'}
                    {...register('city', { required: true })}
                  />
                  <input
                    className={errors.state && 'error'}
                    placeholder={'State / Country*'}
                    {...register('state', { required: true })}
                  />
                  <input placeholder={'Phone'} {...register('phone')} />
                  <input
                    className={errors.email && 'error'}
                    placeholder={'Email*'}
                    {...register('email', { required: true })}
                  />
                  <button type="submit">Place order</button>
                  <div className="">
                    © {new Date().getFullYear()}-{new Date().getFullYear() + 1}.{' '}
                    <br />A Spectr. All rights reserved.
                  </div>
                </form>
              </div>
            </>
          )}
          {state.orderStatus === 'SUCCESS' && (
            <div className="bag-content_success">
              <div className="bag-content_success-item">
                <p className="bag-content_success-message">
                  Your order has been successfully placed.
                </p>
                <p className="bag-content_success-message">
                  We will contact you via [email/phone] within 2 working days to
                  confirm your order and/or collect any additional information
                  needed.
                </p>
              </div>
              <div className="bag-content_success-btn">Order placed</div>
              <div className="">
                © {new Date().getFullYear()}-{new Date().getFullYear() + 1}.{' '}
                <br />A Spectr. All rights reserved.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
