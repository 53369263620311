import './art.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../components/navigation/naviagation';
import { data } from '../../shared/data/cards';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../../store/order';
import { useState } from 'react';

export default function Art() {
  const { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    navigate('/');
  }
  const art = data.find((el) => id && el.id === +id);

  const [sizeData, setSize] = useState(art?.sizes?.[0]);
  const state = useSelector((state: any) => state.order);
  const dispatch = useDispatch();

  const stateItem = state.order.find((el: any) => el.payload.id === art?.id);

  const orderItemData = () => {
    return {
      id: art?.id,
      author: art?.author,
      work: art?.work,
      sizeData: sizeData,
      quantity: 1,
    };
  };

  return (
    <div>
      <Navigation />
      <div className="art-item">
        <div className="art-item__image-box">
          <img src={'/img/' + sizeData?.fileName} alt="art-item__image" />
        </div>
        <div className="art-item__info-box">
          <div className="item-title title">
            <p>{art?.work}</p>
            <p>€ {sizeData?.price}</p>
          </div>

          <div className="item-info text">
            <p className="item-label">By:</p>
            <Link
              to={`/artist/${art?.author.replace(' ', '_')}`}
              className="item-author text"
            >
              {art?.author}
            </Link>
          </div>

          {art?.placeTime && (
            <div className="item-info text">
              <p className="item-label">Location/Year:</p>
              <p>{art?.placeTime}</p>
            </div>
          )}

          <div className="item-info text">
            <fieldset className="size-radio">
              <legend className="item-label">Size:</legend>
              {art?.sizes?.map((el, index) => {
                return (
                  <div key={index}>
                    <input
                      onChange={() => setSize(el)}
                      className="radio-btn"
                      id={`size${index}`}
                      type="radio"
                      checked={el.size === sizeData?.size}
                    />
                    <label htmlFor={`size${index}`}>{el.size}</label>
                  </div>
                );
              })}
            </fieldset>
          </div>

          <div className="item-info text">
            <p className="item-label">Availability:</p>
            <p>{sizeData?.circulation}</p>
          </div>

          {art?.shippingFrom && (
            <div className="item-info text">
              <p className="item-label">Shipping from:</p>
              <p>{art?.shippingFrom}</p>
            </div>
          )}

          <div>
            {stateItem ? (
              <button className="order-btn added text">Added</button>
            ) : (
              <button
                className="order-btn text"
                onClick={() => dispatch(addItem(orderItemData()))}
              >
                Add to Bag
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
