import Cards from '../../components/cards/cards';
import Footer from '../../components/footer/footer';
import MainInfo from '../../components/main-info/main-info';
import Navigation from '../../components/navigation/naviagation';
import { data } from '../../shared/data/cards';

export default function Main() {
  return (
    <div className="">
      <Navigation />
      <MainInfo />
      <Cards data={data} />
      <Footer />
    </div>
  );
}
