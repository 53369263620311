import ArtistsList from "../../components/artists-list/artists-list";
import Navigation from "../../components/navigation/naviagation";

export default function Artists() {
  return (
    <div className="">
      <Navigation />
      <ArtistsList />
    </div>
  );
}
