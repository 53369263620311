export const artists = [
  {
    name: 'Yurko Dyachyshyn',
    bio: 'Artist. Photography, collage, street art, installation. Born in 1980 in Lviv, Ukraine. Since 2003 documented daily life the city of Lviv. His works have been exhibited in many personal exhibitions and festivals and won many awards. Works are in private collections. Yurko’s works have been published in the New York Times, CNN, The Telegraph, Der Spiegel, Die Weltwoche‎, Mirror, La Repubblica, Metronews, Publimetro, De Standaard, Esquire Ukraine, L’Hebdo, Los Angeles Times, The Wall Street Journal, Washington Post, Feature Shoot, and many others. Lives and works in Lviv, Ukraine.',
    url: 'https://dyachyshyn.com',
  },
  {
    name: 'Vadym Khudoliy',
    bio: 'Born in Simferopol, Crimea. Currently works and lives in Kyiv, Ukraine. Graduated from the School of Art & Architecture Academy in Crimea, and has worked in the film production industry since 2008. In 2006, moved to Kyiv to begin a career in journalism. Personal multidisciplinary art projects include visual art, photography, and amateur cinema. In 2016, published first photography anthology entitled “DREAM”, dedicated to experiencing the annexation of Crimea and the loss of home; and during the following year film “Light of the Light” was born. 2021 photobook “ESTRANGEMENT“. Since 2018, has been collaborating with the duo Yarema and Himey as a colour corrector. Worked on documentaries, features, and short films such as: “State Institution” (2017), “To Whom Have You Abandoned Us Our Father” (2018, PinchukArtCentre Award), “New Jerusalem” (2020), “Live Stream” (2020, Main Prize, PinchukArtCentre). Also collaborated with Mikola Ridniy, Sasha Kurmaz and Nikita Kadan. In 2022 participated in various foreign charity auctions in order to raise money for Ukraine. Events were held in London, Toronto, Seattle, Berlin. Moya Ridna (Ukrainian for “my darling”, “my special one”) is a fundraising exhibition and print sale showcasing works by 14 artists from Ukraine. Participation in the collective book Сподіваюсь (HOPE) in order to raise money for charity. The book features Ukrainian young photographers and several world stars. All funds raised will go to Kyiv Angels and Livyi Bereh, two organizations providing support to Ukrainians affected by the war and those in desperate need on the eastern frontlines.',
    url: 'https://vadimkhudoliy.com',
  },
  {
    name: 'Zhenya Trifonova',
    bio: 'A multidisciplinary artist based in Kyiv, her photographic works range from pastoral scenes to candid portraiture, effortlessly composed and often haunting glimpses of her native environment. She is also a curator of home decorator, a seamless blend of antique and handmade, objects stylized to maximize their decorative, aesthetic potential.',
    url: 'https://instagram.com/firmkick',
  },
  {
    name: 'Maxym Dutka',
    bio: '',
    url: 'https://instagram.com/maxdutka',
  },
  {
    name: 'Maksym Zatsarinnyi',
    bio: "Maksym Zatsarinnyi comes from a small town Zvenyhorodka of Cherkassy region. Being interested in visual arts since he was a child, he finishes specialized school in Shevchenkovo village and attends private lessons of the Ukrainian artist Oleksa Blyzniuk, who introduces Maksym to Ukrainian Impressionism. Maksym enters Kosiv Institute of Applied and Decorative Art, where he starts his digital art project Molizm. In this project the artist explores the depths of subconscious and starts expressing his feelings of melancholy and darkness. In 2016 Maksym joins an art group Fabrika and together with them does group exhibitions in different cities of Ukraine. At that time he gains mysterious experience and through darkness starts seeing light and love. Maksym starts using the camera on his cellphone to take lo-fi photos and videos and calls this media project 'Svitlo' (The Light). In search of the truth he studies philosophy and starts giving lectures on his vision and art.",
    url: 'https://instagram.com/m.svitlo',
  },
  {
    name: 'Ira Kremer',
    bio: 'Ukrainian-Israeli multidisciplinary artist, who works with such media as painting, photography, video art, sound art, installation, performance. In her works, Kremer touches topics of alter egos, physicality, and search of home as an emotional statement and so on. Ira was born on 10th of December 1989 in Poltava, Ukraine. 2006 – Ira graduated Poltava’s pedagogical university where she studied philology and literature. 2012 – moved to Tel Aviv, Israel. 2019 – graduated Israeli art school “Basis”. 2023 – graduated MYPH (Mykolaiv photography school).',
    url: 'https://instagram.com/myalteregorealme',
  },
  {
    name: 'Elena Subach',
    bio: 'Elena Subach (1980) is a Ukrainian visual artist and photographer. Born in Chervonohrad, Ukraine. Currently based in Lviv, Ukraine. Elena studied economics and received her master`s degree in Volyn State University in Ukraine before she turned to photography. In an auto-didactic way, she developed her own unique vision on Ukrainian visual culture. In her artistic practice, Elena is concerned with questioning religion, tradition, the construction of history and the consequences of soviet colonial pasts. Since 2018, Elena has developed these ideas in her project on Grandmothers on the Edge of Heaven, for which she is researching and photographing crowded religious celebrations and ceremonies in Ukraine. The urge to show this rich Ukrainian heritage through an artistic eye is present in everything she captures and the inspiration she gets from her motherland is endless.',
    url: 'https://elenasubach.com',
  },
  {
    name: 'Yegor Parker',
    bio: 'Having lived in the place of Melitopol. Motivation for this period has already been strongly connected to the development of creative personality. Studied in the audio visual art in Institute of Cinema and Television. From the very cob, just to the photo right away, it became a little more wider, and I started to create complex art work in multi art area. In 2019, he won a diploma from the university with an immersive show-performance on the theme of flea market in Ukraine. Having taken the lowest rating and the most respect and praise. Having delved deeper into the topic of absurd, kitch and religion and now it’s good to go to new functions. Having made a photo of a series on this topics.',
    url: 'https://instagram.com/yegorparker',
  },
  {
    name: 'Veronika Sarafanova',
    bio: 'Photographer and videographer, based in Kyiv and working under the pseudonym SARAFANNOVA, processes photos, focusing on a large number of bright colors and interesting textures in the photo. She participates in various video production projects as a costume designer and backstage designer.',
    url: 'https://instagram.com/sarafannova',
  },
  {
    name: 'Artem Hymilevskyi',
    bio: 'Artem Humilevskiy (1986, Mykolaiv) - a Ukrainian photographer. Had studied engineering at the Odesa National Academy of Food Technologies, after which enrolled in the Department of Economics at University "Krok". Since 2003 has been one of senior managers of an agrarian company; in 2015-2020 worked as consultant in culture, education and sport at the Mykolaiv Regional Counsil. Got engaged in commercial photography in 2019; the same year took a course of art photographt at the myph school of Sergey Melnitchenko. Shortlisted in "Nida off" contest of the International Photography Symposium (2021) and PinchukArtCentre prize 2022. Global peace photo award 2022 winner.',
    url: 'https://humilevskiy.com',
  },
  {
    name: 'Daria Svertilova',
    bio: 'Born in 1996 in Odesa, Ukraine. Daria is a Ukrainian photographer and visual artist currently based in Paris and Kyiv. She graduated from École Nationale Supérieure des Arts Décoratifs of Paris (M.A. in Photography & Video) in 2023. Her work was exhibited across Europe and in the US including Cité des Arts, Paris, Open Eye Gallery, Liverpool, Urania, Berlin, Mystetskiy Arsenal, Kyiv and published in M le Monde, SZ Magazin, i-D, Libération, The Guardian among others. Daria is a member of Women Photograph and finalist of Palm* Photo Prize 2022.',
    url: 'https://dariasvertilova.com',
  },
  {
    name: 'Sofia Lupul',
    bio: 'Photographer focusing primarily on art photography, fashion and street photography. Was born in 1998 in Сhernivtsi, Ukraine. From the childhood life was living in Lviv. Throughout my whole life, I have been learning to live life, discover myself, stay true to myself, and make choices. And throughout my life, art has helped me - to find my own. I am very grateful for this accompaniment; I have been breathing it since childhood - unconsciously and effortlessly, up until today - consciously, where curiosity replaces fear, and sometimes vice versa. I call it life and a point of growth :)',
    url: 'https://behance.net/Sofibird',
  },
  {
    name: 'Taras Bychko',
    bio: 'Taras Bychko works in the genre of documentary and street photography. Starts photography in 2014. He was born in Lviv (Ukraine) in 1987. His works were exhibited at the main expositions of the Miami Street Photo Festival (2016, 2017, 2018), Eastreet 4 – photography of Eastern Europe (2017), Odessa / Batumi Photo days (2017), Leica Street Photo (2018) and many other international and Ukrainian photo competitions. The winner of the Ukrainian National Competition “Photographer of the Year 2016” in the category “Street Photography”. Finalist and 2nd Best Overall Photo winner at Documentary Family Awards (2018). His photo was included in the book “100 Great Street Photographs” (Prestel, 2017). Also he was publicated in Burn Magazine, In-Public, Burn My Eye, National Geographic, Lens Culture, 121 clicks, Bird in flight and others. Taras is one of creator of group Ukranian Street Photography.',
    url: 'https://bychko.com',
  },
  {
    name: 'Veronika Mol',
    bio: 'Veronika Mol was born in 1994 in Korsun-Shevchenkivskyi, Cherkasy region, Ukraine. She graduated from Bohdan Khmelnytsky Cherkasy National University in 2015, specializing in publishing and editing. Since 2014 she has been living and working in Kyiv. She started doing photography in 2021. Veronika is a member of the MYPH group. Works with analog photography, in particular instant cameras. The artist’s inspiration stems from the processing of developed film: extending, cutting, burning, dyeing and steel staples stitching to satisfy her endless curiosity.',
    url: 'https://instagram.com/veronihue',
  },
  {
    name: 'Natalia Filatova',
    bio: "Born in Ukraine, Donetsk. Graduated from Vasyl' Stus DonNU. Since 2011 has been working with Polaroid and Instax instant printing cameras. In 2014 moved to Kharkiv. Now works in Lviv. She is a participant in Ukrainian and international collective expositions dedicated to instant photography.",
    url: 'https://instagram.com/shkeltsya',
  },
  {
    name: 'Dima Tolkachov',
    bio: 'Dima is a Ukrainian visual artist based in Kyiv. He works with photography and experiments with digital graphic techniques. His practice explores patterns of human behavior in public spaces, interactions of human-made objects with environments, vernacular creativity, and the feeling of uncertainty in the modern world. His work was exhibited in Ukraine, Poland, and Germany, published in The EEP Berlin Magazine Vol. 1. and selected for the Reclaim Award 2022 show in Cologne.',
    url: 'https://dimatolkachov.cargo.site',
  },
  {
    name: 'Hanna Tykha',
    bio: "Born and live in Kyiv. Engaged in animation, directing and editing. She is a member of Babylon'13 association of cinematographers. Author of music videos for the Pree Tone band and noise projects Kavkazka Sound, Emotional Anhedonia. Passionate about experimental shooting methods, wildlife and clouds.",
    url: 'https://instagram.com/polystave',
  },
  {
    name: 'Igor Tsuker',
    bio: 'Igor Tsuker is a photographer from Odesa. Born in Mykolaiv. He started shooting while studying as a designer in Poland, learning mostly on YouTube. Style of works - Psychedelic Melancholia.',
    url: 'https://instagram.com/igor_tsuker',
  },
  {
    name: 'Yana Kononova',
    bio: "Artist Yana Kononova has an academic background in social sciences, and holds a PhD in Sociology. She was born on Pirallahi island in the Caspian Sea, Azerbaijan and now based in Kyiv, Ukraine. Yana's work encompasses research in different subjects of art in their attitude to broader topics and in regard to the ways they have been conceptualised in the history of culture. Her current research focus is on seeking the spatiality and materiality image of inhuman environments while examining how different motifs and gravitations in art at different historical moments have been communicated with or affected by the issue of the inhuman. Yana's practice is greatly inspired by the pieces of the contemporary philosophical genre of ‘theory-fiction’ and by the black and white topographies of Chinese painting of Song dynasty which influenced the artist's concern with the materiality of a photography-based work. Quite often her approach is to create photographs on the ‘threshold’ between image and the material process that provides it. Yana makes use of analogy photography, texts and video in her practice.",
    url: 'https://yanakononova.com',
  },
  {
    name: 'Valentine Bo',
    bio: "Born in 1985, Lutsk. He is an artist currently living and working in Kyiv, UA. Valentine graduated from the Architecture Institute of the Lviv National University in 2007 receiving a master's degree in architecture. In 2010 became a member of UPHA — Ukrainian Photographic Alternative (a small independent group created to help and support development of Ukrainian contemporary photography). Since 2012 his work has been exhibited in group shows in the USA, Sweden, Poland, and the UK.",
    url: 'https://instagram.com/valentine__bo',
  },
];
