import './contact.scss';

import Navigation from '../navigation/naviagation';
import Footer from '../footer/footer';

export default function Info() {
  return (
    <div className="info">
      <Navigation />
      <div className="info-data">
        <div className="info-data_items">
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Shipping</h2>
            We ship internationally. You will receive an email with a tracking number upon confirmation of your purchase,
            and a follow-up email once your order will be shipped. It would take about 10-30 days for your order to arrive
            depending on the final destination, additional charge will apply. All deliveries over 200 euro are free of charge.<br />
            For work printed in EU it takes 3-8 days for your order to arrive depending on the final destination and is free of charge.
            In Ukraine shipment takes 2-3 days and is free of charge.
          </div>
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Processing Time</h2>
            We are processing orders every week. All orders will be printed out
            once a week and then sent to you. The pickup orders will be ready
            the same day as we receive them from our printer.
          </div>
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Printing/Paper</h2>
            We use plotter Epson SureColor SC-P8000 for photographic prints.<br />Most of the works are printed on Hahnemuhle paper.
          </div>
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Custom Size</h2>
            If you would like to order a custom size, please reach out to us using our info page. We are happy to help and make it work the best for you!
          </div>
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Currency</h2>
            All transactions are processed in EUR.
          </div>
          <div className="info-data_item text-bold">
            <h2 className="info-data_title title">Payment</h2>
            After placing an order, we will provide you with payment details.
            Payment can be made via bank transfer or alternative methods
            such as: Paypal, Bitcoin, ETH, USDT.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
