'use client';

import './footer.scss';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_contact">
        <span className="title">support@aspectr.org</span>
        <br />
        <a
          className="title"
          href="https://www.instagram.com/_aspectr_/"
          target="_blank"
        >
          Instagram
        </a>
      </div>
      <div className="footer_info text">
        <div className="footer_info-item">
          © {new Date().getFullYear()}-{new Date().getFullYear() + 1}. <br />A
          Spectr. All rights reserved.
        </div>
        <div className="footer_info-item">
          Typeface: <br />
          Kyiv Type Foundry.
        </div>
        <div className="footer_info-item">
          Design: <br />
          Unknown Author.
        </div>
      </div>
    </div>
  );
}
