export interface Art {
  author: string;
  work: string;
  sizes: {
    fileName: string;
    size: string;
    circulation: number;
    price: number;
  }[];
  order: number;
  id: number;
  shippingFrom?: string;
  placeTime?: string | number;
  paper?: string;
  projectDescription?: string;
}

export const data: Art[] = [
  {
    author: 'Yurko Dyachyshyn',
    work: 'Terra Galicia #1',
    sizes: [
      {
        fileName: 'Terra-Galicia_DY1_0405.jpg',
        size: '60х90 см',
        circulation: 8,
        price: 250,
      },
      {
        fileName: 'Terra-Galicia_DY1_0405.jpg',
        size: '80х120 см',
        circulation: 8,
        price: 350,
      },
    ],
    order: 1,
    id: 1,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Galicia (Halychyna) 2020',
    paper: 'Hahnemuhle Photo Rag',
    projectDescription:
      'Terra Galicia is a project by Yurko Dyachyshyn that devoted to the land and scenery of Galicia (Halychyna)  the historical and geographic region in western Ukraine.It shows the region’s  landscapes,  their unsophisticated beauty, diversity and spirit that are sometimes ignored because of their simplicity and unpretentiousness. Terra Galicia is an “undiscovered” and unexplored land even though it is nearby. Terra Galicia is, in essence, Terra Incognita.On the one hand, Terra Galicia is Halychyna but, on the other hand, it is a separate planet. As you watch these cosmic landscapes you can imagine traveling in space and visiting various places of the unknown planet Terra Galicia.',
  },
  {
    author: 'Yurko Dyachyshyn',
    work: 'Terra Galicia #2',
    sizes: [
      {
        fileName: 'Terra-Galicia_DY1_5339-10х15.jpg',
        size: '60х90 см',
        circulation: 8,
        price: 250,
      },
      {
        fileName: 'Terra-Galicia_DY1_5339-10х15.jpg',
        size: '80х120 см',
        circulation: 8,
        price: 350,
      },
    ],
    order: 9,
    id: 2,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Galicia (Halychyna) 2020',
    paper: 'Hahnemuhle Photo Rag',
    projectDescription:
      'Terra Galicia is a project by Yurko Dyachyshyn that devoted to the land and scenery of Galicia (Halychyna)  the historical and geographic region in western Ukraine.It shows the region’s  landscapes,  their unsophisticated beauty, diversity and spirit that are sometimes ignored because of their simplicity and unpretentiousness. Terra Galicia is an “undiscovered” and unexplored land even though it is nearby. Terra Galicia is, in essence, Terra Incognita.On the one hand, Terra Galicia is Halychyna but, on the other hand, it is a separate planet. As you watch these cosmic landscapes you can imagine traveling in space and visiting various places of the unknown planet Terra Galicia.',
  },
  {
    author: 'Yurko Dyachyshyn',
    work: 'Terra Galicia #3',
    sizes: [
      {
        fileName: 'Terra-Galicia_DY1_7698-10х15.jpg',
        size: '60х90 см',
        circulation: 8,
        price: 250,
      },
      {
        fileName: 'Terra-Galicia_DY1_7698-10х15.jpg',
        size: '80х120 см',
        circulation: 8,
        price: 350,
      },
    ],
    order: 5,
    id: 3,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Galicia (Halychyna) 2020',
    paper: 'Hahnemuhle Photo Rag',
    projectDescription:
      'Terra Galicia is a project by Yurko Dyachyshyn that devoted to the land and scenery of Galicia (Halychyna)  the historical and geographic region in western Ukraine.It shows the region’s  landscapes,  their unsophisticated beauty, diversity and spirit that are sometimes ignored because of their simplicity and unpretentiousness. Terra Galicia is an “undiscovered” and unexplored land even though it is nearby. Terra Galicia is, in essence, Terra Incognita.On the one hand, Terra Galicia is Halychyna but, on the other hand, it is a separate planet. As you watch these cosmic landscapes you can imagine traveling in space and visiting various places of the unknown planet Terra Galicia.',
  },
  {
    author: 'Yurko Dyachyshyn',
    work: 'Terra Galicia #4',
    sizes: [
      {
        fileName: 'Terra-Galicia_DY1_8036-10х15.jpg',
        size: '60х90 см',
        circulation: 8,
        price: 250,
      },
      {
        fileName: 'Terra-Galicia_DY1_8036-10х15.jpg',
        size: '80х120 см',
        circulation: 8,
        price: 350,
      },
    ],
    order: 16,
    id: 4,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Galicia (Halychyna) 2020',
    paper: 'Hahnemuhle Photo Rag',
    projectDescription:
      'Terra Galicia is a project by Yurko Dyachyshyn that devoted to the land and scenery of Galicia (Halychyna)  the historical and geographic region in western Ukraine.It shows the region’s  landscapes,  their unsophisticated beauty, diversity and spirit that are sometimes ignored because of their simplicity and unpretentiousness. Terra Galicia is an “undiscovered” and unexplored land even though it is nearby. Terra Galicia is, in essence, Terra Incognita.On the one hand, Terra Galicia is Halychyna but, on the other hand, it is a separate planet. As you watch these cosmic landscapes you can imagine traveling in space and visiting various places of the unknown planet Terra Galicia.',
  },
  {
    author: 'Vadym Khudoliy',
    work: 'Mirkwood',
    sizes: [
      {
        fileName: 'Mirkwood.jpg',
        size: '40x60 см',
        circulation: 10,
        price: 150,
      },
      {
        fileName: 'Mirkwood.jpg',
        size: '60х90 см',
        circulation: 10,
        price: 200,
      },
    ],
    order: 4,
    id: 5,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Crimea 2014',
    paper: 'Epson Enhanced Matte ',
    projectDescription:
      'The photo was taken in the Crimea in 2014 New Year holidays. I came home and walked with friends in the mountains. Life seemed magical in serenity. No signs of trouble.',
  },
  {
    author: 'Vadym Khudoliy',
    work: "Forester's house",
    sizes: [
      {
        fileName: 'Forester_s-house.jpg',
        size: '40x60 см',
        circulation: 10,
        price: 150,
      },
      {
        fileName: 'Forester_s-house.jpg',
        size: '60х90 см',
        circulation: 10,
        price: 200,
      },
    ],
    order: 18,
    id: 6,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Crimea 2015',
    paper: 'Epson Enhanced Matte ',
    projectDescription:
      'In the fall of 2015 after the annexation of Crimea. I came for a month to my parents and it seemed to me that time was running out. I wandered alone through the mountains and melancholy enjoyed the elusive beauty of my lost home. I wanted to see as much as possible. There was a feeling that one of the visits could be the last.',
  },
  {
    author: 'Vadym Khudoliy',
    work: 'So this is goodbye',
    sizes: [
      {
        fileName: 'So-this-is-goodbye.jpg',
        size: '40x60 см',
        circulation: 10,
        price: 150,
      },
      {
        fileName: 'So-this-is-goodbye.jpg',
        size: '60х90 см',
        circulation: 10,
        price: 200,
      },
    ],
    order: 14,
    id: 7,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Carpathians 2019',
    paper: 'Epson Enhanced Matte ',
    projectDescription:
      'One of the most powerful moments of my life. A flash of clarity. The beginning of something that was never meant to be. Exit from alienation along a foggy winding path.',
  },
  {
    author: 'Vadym Khudoliy',
    work: 'GONDOR',
    sizes: [
      {
        fileName: 'Gondor.jpg',
        size: '40x60 см',
        circulation: 10,
        price: 150,
      },
      {
        fileName: 'Gondor.jpg',
        size: '60х90 см',
        circulation: 10,
        price: 200,
      },
    ],
    order: 2,
    id: 8,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Crimea, Ai-Petri 2019',
    paper: 'Epson Enhanced Matte ',
    projectDescription:
      'The photo was taken at the top of Mount Ai-Petri in 2019. I thought about how changeable the fate of my home. A beautiful kingdom cursed with treachery, to which I can someday return as my home.',
  },
  {
    author: 'Zhenya Trifonova',
    work: 'Cold moonlit night in Verkhovyna',
    sizes: [
      {
        fileName: 'Cold-moonlit-night-in-Verkhovyna.jpg',
        size: '30X40',
        circulation: 5,
        price: 100,
      },
    ],
    order: 8,
    id: 9,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Carpathians 2022',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Zhenya Trifonova',
    work: 'Fall palette/ blood and smoke',
    sizes: [
      {
        fileName: 'Fall-palette-blood-and-smoke.jpg',
        size: '40X50',
        circulation: 5,
        price: 100,
      },
    ],
    order: 6,
    id: 10,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2022',
    paper: 'Hahnemuhle German Etching',
    projectDescription: 'Autumn evening at Dovzhenko film studio',
  },
  {
    author: 'Maxym Dutka',
    work: 'Stables',
    sizes: [
      {
        fileName: 'Stables.jpg',
        size: '23х40',
        circulation: 10,
        price: 100,
      },
    ],
    order: 29,
    id: 11,
    shippingFrom: 'Dusseldorf, Germany',
    placeTime: 'Dusseldorf 2023',
    paper: 'handcrafted canvas',
  },
  {
    author: 'Maxym Dutka',
    work: 'I and cock',
    sizes: [
      {
        fileName: 'I-and-cock.jpg',
        size: '30х40',
        circulation: 10,
        price: 150,
      },
    ],
    order: 37,
    id: 12,
    shippingFrom: 'Dusseldorf, Germany',
    placeTime: 'Dusseldorf 2023',
    paper: 'handcrafted canvas',
  },
  {
    author: 'Maxym Dutka',
    work: 'Tiko',
    sizes: [
      {
        fileName: 'Tiko.jpg',
        size: '35х50',
        circulation: 10,
        price: 150,
      },
    ],
    order: 47,
    id: 13,
    shippingFrom: 'Dusseldorf, Germany',
    placeTime: 'Paris 2021',
    paper: 'handcrafted canvas',
  },
  {
    author: 'Maxym Dutka',
    work: 'stuck in the cage',
    sizes: [
      {
        fileName: 'stuck.jpg',
        size: '30х40',
        circulation: 10,
        price: 150,
      },
    ],
    order: 31,
    id: 14,
    shippingFrom: 'Dusseldorf, Germany',
    placeTime: 'Dusseldorf 2023',
    paper: 'handcrafted canvas',
  },
  {
    author: 'Maksym Zatsarinnyi',
    work: ' Сповідь',
    sizes: [
      {
        fileName: 'IMG_2332.jpg',
        size: '20x15',
        circulation: 5,
        price: 100,
      },
    ],
    order: 24,
    id: 15,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Swinoujscie 2019',
    paper: 'Hahnemuhle German Etching',
    projectDescription: 'Somewhere on the edge of the world I see confession',
  },
  {
    author: 'Maksym Zatsarinnyi',
    work: 'Казочка про метелика',
    sizes: [
      {
        fileName: 'IMG_2328.jpg',
        size: '20x15',
        circulation: 5,
        price: 100,
      },
    ],
    order: 35,
    id: 16,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Lviv 2018',
    paper: 'Hahnemuhle German Etching',
    projectDescription:
      'Lilac blossomed on a fabulous day, attracted the attention of a butterfly',
  },
  {
    author: 'Maksym Zatsarinnyi',
    work: 'Мешканець',
    sizes: [
      {
        fileName: 'IMG_2329.jpg',
        size: '20x15',
        circulation: 5,
        price: 100,
      },
    ],
    order: 23,
    id: 17,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Lviv 2018',
    paper: 'Hahnemuhle German Etching',
    projectDescription: 'Mantis who lives in Lviv',
  },
  {
    author: 'Maksym Zatsarinnyi',
    work: 'Квіткова буря',
    sizes: [
      {
        fileName: 'IMG_2330.jpg',
        size: '20x15',
        circulation: 5,
        price: 100,
      },
    ],
    order: 39,
    id: 18,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Lviv 2018',
    paper: 'Hahnemuhle German Etching',
    projectDescription:
      'Flowers in the middle of the night on a blooming spring day',
  },
  {
    author: 'Elena Subach',
    work: 'Моя тінь зникає ',
    sizes: [
      {
        fileName: 'Моя-тінь-зникає---2022-.jpg',
        size: '50x33',
        circulation: 9,
        price: 200,
      },
    ],
    order: 48,
    id: 19,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 2022,
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Yegor Parker',
    work: 'Wind my hair',
    sizes: [
      {
        fileName: 'IMG_7366.jpg',
        size: '60х70',
        circulation: 5,
        price: 150,
      },
    ],
    order: 44,
    id: 20,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Veronika Sarafanova',
    work: 'Whitesnake',
    sizes: [
      {
        fileName: 'Yeva-I.jpg',
        size: '40x50 ',
        circulation: 10,
        price: 100,
      },
    ],
    order: 38,
    id: 21,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2021',
    paper: 'Hahnemuhle Photo Rag or Baryta',
  },
  {
    author: 'Veronika Sarafanova',
    work: 'Snake Kiss',
    sizes: [
      {
        fileName: 'Yeva-III.jpg',
        size: '40x50 ',
        circulation: 10,
        price: 100,
      },
    ],
    order: 42,
    id: 22,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2021',
    paper: 'Hahnemuhle Photo Rag or Baryta',
  },
  {
    author: 'Artem Hymilevskyi',
    work: 'Blood Bath',
    sizes: [
      {
        fileName: 'Humilevskyi_roots_Blood-bath1.jpg',
        size: '50х70см',
        circulation: 7,
        price: 200,
      },
    ],
    order: 46,
    id: 23,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Artem Hymilevskyi',
    work: '#50',
    sizes: [
      {
        fileName: 'Artem-Hymilevskyi50.jpg',
        size: '50х70см',
        circulation: 7,
        price: 200,
      },
    ],
    order: 50,
    id: 24,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Daria Svertilova',
    work: 'Roses on my way home',
    sizes: [
      {
        fileName: 'Daria-Svertilova---roses.jpg',
        size: '25х30',
        circulation: 5,
        price: 150,
      },
    ],
    order: 40,
    id: 25,
    shippingFrom: 'Paris, France',
    placeTime: 'Paris 2022',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Daria Svertilova',
    work: 'Defender of Kyiv',
    sizes: [
      {
        fileName: 'Daria-Svertilova---archangel.jpg',
        size: '30x40',
        circulation: 5,
        price: 200,
      },
    ],
    order: 20,
    id: 26,
    shippingFrom: 'Paris, France',
    placeTime: 'Kyiv 2022',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Sofia Lupul',
    work: 'Натюрморт ',
    sizes: [
      {
        fileName: 'Sofiia_Lupul_prints_small.jpg',
        size: '50х70см',
        circulation: 2,
        price: 200,
      },
    ],
    order: 28,
    id: 27,
    shippingFrom: 'Gdansk, Poland',
    placeTime: 'Gdansk 2022',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Sofia Lupul',
    work: 'Рефлексія',
    sizes: [
      {
        fileName: 'Sofiia_Lupul_prints_small-3.jpg',
        size: '21х30',
        circulation: 2,
        price: 100,
      },
    ],
    order: 26,
    id: 28,
    shippingFrom: 'Gdansk, Poland',
    placeTime: 'Gdansk 2022',
    paper: 'fineart museum etching',
  },
  {
    author: 'Sofia Lupul',
    work: 'Сенси Спокою',
    sizes: [
      {
        fileName: 'Sofiia_Lupul_prints_small-1.jpg',
        size: '50х70см',
        circulation: 2,
        price: 200,
      },
    ],
    order: 36,
    id: 29,
    shippingFrom: 'Gdansk, Poland',
    placeTime: 'Milan 2023',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Sofia Lupul',
    work: 'Листівка Життя',
    sizes: [
      {
        fileName: 'Sofiia_Lupul_prints_small-2.jpg',
        size: '50х70см',
        circulation: 2,
        price: 200,
      },
    ],
    order: 41,
    id: 30,
    shippingFrom: 'Gdansk, Poland',
    placeTime: 'Milan 2022',
    paper: 'Hahnemuhle Baryta',
  },
  {
    author: 'Taras Bychko',
    work: 'Barvy',
    sizes: [
      {
        fileName: 'Taras_Bychko_Barvy_UK_2022.jpg',
        size: '30x40',
        circulation: 10,
        price: 130,
      },
      {
        fileName: 'Taras_Bychko_Barvy_UK_2022.jpg',
        size: '40x60 ',
        circulation: 10,
        price: 150,
      },
    ],
    order: 32,
    id: 31,
    shippingFrom: 'London, UK',
    placeTime: 'UK 2022',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Taras Bychko',
    work: 'Barvy #1',
    sizes: [
      {
        fileName: 'Taras_Bychko_Barvy1_UK_2022.jpg',
        size: '30x40',
        circulation: 10,
        price: 130,
      },
      {
        fileName: 'Taras_Bychko_Barvy1_UK_2022.jpg',
        size: '40x60 ',
        circulation: 10,
        price: 150,
      },
    ],
    order: 34,
    id: 32,
    shippingFrom: 'London, UK',
    placeTime: 'UK 2022',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Taras Bychko',
    work: 'The Breeze',
    sizes: [
      {
        fileName: 'Taras_Bychko_TheBreeze_UK_2022.jpg',
        size: '20x20',
        circulation: 10,
        price: 100,
      },
      {
        fileName: 'Taras_Bychko_TheBreeze_UK_2022.jpg',
        size: '30x30',
        circulation: 10,
        price: 130,
      },
    ],
    order: 11,
    id: 33,
    shippingFrom: 'London, UK',
    placeTime: 'UK 2022',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Taras Bychko',
    work: 'Incognito',
    sizes: [
      {
        fileName: 'Taras_Bychko_Incognito_Wroclaw_PL_2022.jpg',
        size: '20x25',
        circulation: 10,
        price: 100,
      },
    ],
    order: 52,
    id: 34,
    shippingFrom: 'London, UK',
    placeTime: 'Wroclaw 2023',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Veronika Mol',
    work: '#1',
    sizes: [
      {
        fileName: '2_Polaroid 20x24cm.jpg',
        size: '20x24',
        circulation: 10,
        price: 75,
      },
      {
        fileName: '2_square 30x30cm.jpg',
        size: '30x30',
        circulation: 8,
        price: 100,
      },
    ],
    order: 30,
    id: 35,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2022',
    paper: 'photorag (satin?)',
  },
  {
    author: 'Veronika Mol',
    work: '#2',
    sizes: [
      {
        fileName: '9_polaroid 20x24cm.jpg',
        size: '20x24',
        circulation: 10,
        price: 75,
      },
      {
        fileName: '9_square 30x30cm.jpg',
        size: '30x30',
        circulation: 8,
        price: 100,
      },
    ],
    order: 45,
    id: 36,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Nuremberg Archive',
    paper: 'photorag (satin?)',
  },
  {
    author: 'Natalia Filatova',
    work: "Kyiv'23",
    sizes: [
      {
        fileName: 'Blackout-30x25.jpg',
        size: '20x24',
        circulation: 8,
        price: 75,
      },
      {
        fileName: 'Blackout-20x20.jpg',
        size: '30x30',
        circulation: 8,
        price: 75,
      },
    ],
    order: 22,
    id: 37,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2023',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Natalia Filatova',
    work: 'Last Weekend',
    sizes: [
      {
        fileName: 'Last-Weekend-30-x25.jpg',
        size: '20x24',
        circulation: 8,
        price: 75,
      },
      {
        fileName: 'Last-Weekend-20-x20.jpg',
        size: '30x30',
        circulation: 8,
        price: 75,
      },
    ],
    order: 17,
    id: 38,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2023',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Natalia Filatova',
    work: 'Провесна/ Primaveral',
    sizes: [
      {
        fileName: 'Ritual-img30х25.jpg',
        size: '20x24',
        circulation: 8,
        price: 75,
      },
      {
        fileName: 'Правесна-30х20.jpg',
        size: '30x30',
        circulation: 8,
        price: 75,
      },
    ],
    order: 3,
    id: 39,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2023',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Natalia Filatova',
    work: 'Ніч/Night',
    sizes: [
      {
        fileName: 'Night-Hiч-33х30сь.jpg',
        size: '20x24',
        circulation: 8,
        price: 75,
      },
      {
        fileName: 'Night-Hiч-20х20сь.jpg',
        size: '30x30',
        circulation: 8,
        price: 75,
      },
    ],
    order: 21,
    id: 40,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Kyiv 2023',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Dima Tolkachov',
    work: 'eternal return',
    sizes: [
      {
        fileName: 'eternal-return.jpg',
        size: '30x45',
        circulation: 5,
        price: 150,
      },
    ],
    order: 19,
    id: 41,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Dima Tolkachov',
    work: 'місячне сяйво',
    sizes: [
      {
        fileName: 'місячне-сяйво---Moonshine.jpg',
        size: '30x45',
        circulation: 5,
        price: 150,
      },
    ],
    order: 15,
    id: 42,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Dima Tolkachov',
    work: 'a portal to uncertainty',
    sizes: [
      {
        fileName: 'a-portal-to-uncertainty-(2).jpg',
        size: '30x45',
        circulation: 5,
        price: 150,
      },
    ],
    order: 13,
    id: 43,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Dima Tolkachov',
    work: 'Treasure',
    sizes: [
      {
        fileName: 'Treasure.jpg',
        size: '16x24',
        circulation: 5,
        price: 150,
      },
    ],
    order: 33,
    id: 44,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Hanna Tykha',
    work: 'Chromium oxide/ Оксид хрому',
    sizes: [
      {
        fileName: 'Ganna-Tykha-1.jpg',
        size: '20x30',
        circulation: 7,
        price: 75,
      },
      {
        fileName: 'Ganna-Tykha-1.jpg',
        size: '30x45',
        circulation: 7,
        price: 100,
      },
    ],
    order: 7,
    id: 45,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Iceland 2019',
    paper: 'Hahnemuhle German Etching',
  },
  {
    author: 'Hanna Tykha',
    work: 'Space in front of the house/ Простір перед домом',
    sizes: [
      {
        fileName: 'Ganna-Tykha-2.jpg',
        size: '20x30',
        circulation: 7,
        price: 75,
      },
    ],
    order: 25,
    id: 46,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Iceland 2019',
    paper: 'Hahnemuhle German Etching',
  },
  {
    author: 'Hanna Tykha',
    work: 'Giants/ Велетні',
    sizes: [
      {
        fileName: 'Ganna-Tykha-3.jpg',
        size: '20x30',
        circulation: 7,
        price: 75,
      },
      {
        fileName: 'Ganna-Tykha-3.jpg',
        size: '30x45',
        circulation: 7,
        price: 100,
      },
    ],
    order: 27,
    id: 47,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Iceland 2019',
    paper: 'Hahnemuhle German Etching',
  },
  {
    author: 'Igor Tsuker',
    work: '#1',
    sizes: [
      {
        fileName: 'Igor-Tsuker1.jpg',
        size: '30x42',
        circulation: 7,
        price: 75,
      },
    ],
    order: 49,
    id: 48,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Igor Tsuker',
    work: '#2',
    sizes: [
      {
        fileName: 'Igor-Tsuker2.jpg',
        size: '26x34',
        circulation: 7,
        price: 75,
      },
    ],
    order: 53,
    id: 49,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Igor Tsuker',
    work: '#3',
    sizes: [
      {
        fileName: 'Igor-Tsuker3.jpg',
        size: '40x60',
        circulation: 7,
        price: 75,
      },
    ],
    order: 51,
    id: 50,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Yana Kononova',
    work: 'Nympheas #69-30',
    sizes: [
      {
        fileName: 'Nympheas-69-30-sm.jpg',
        size: '72x92',
        circulation: 7,
        price: 350,
      },
    ],
    order: 10,
    id: 51,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Yana Kononova',
    work: 'Nympheas #82-13',
    sizes: [
      {
        fileName: 'Nympheas-82-13-sm.jpg',
        size: '72x92',
        circulation: 7,
        price: 350,
      },
    ],
    order: 12,
    id: 52,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Valentine Bo',
    work: 'RMX 2030',
    sizes: [
      {
        fileName: 'RMX-2030.jpg',
        size: '37х50',
        circulation: 2,
        price: 450,
      },
    ],
    order: 43,
    id: 53,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
  {
    author: 'Ira Kremer',
    work: 'Корови і апельсини',
    sizes: [
      {
        fileName: 'IMG_2661.jpg',
        size: '40x60',
        circulation: 10,
        price: 100,
      },
    ],
    order: 54,
    id: 54,
    shippingFrom: 'Kyiv, Ukraine',
    placeTime: 'Lviv 2019',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Yegor Parker',
    work: 'Church',
    sizes: [
      {
        fileName: 'Yegor_Parker_12.jpg',
        size: '50x70',
        circulation: 5,
        price: 150,
      },
    ],
    order: 20.5,
    id: 55,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle Photo Rag',
  },
  {
    author: 'Valentine Bo',
    work: 'The element/set',
    sizes: [
      {
        fileName: 'The-eleme_nt__set.jpg',
        size: '37х50',
        circulation: 1,
        price: 450,
      },
    ],
    order: 50.5,
    id: 56,
    shippingFrom: 'Kyiv, Ukraine',
    paper: 'Hahnemuhle FineArt Pearl',
  },
];
