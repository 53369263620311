'use client';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import './naviagation.scss';
import { useSelector } from 'react-redux';

import logo from '../../shared/data/svg/logo.svg';
import { useEffect, useState } from 'react';

export default function Navigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const state = useSelector((state: any) => state.order);
  const [transparency, setTransparency] = useState('');

  useEffect(() => {
    function navigationScroll() {
      if (window.pageYOffset < window.innerHeight && pathname === '/') {
        setTransparency('transparent');
      } else {
        setTransparency('background');
      }
    }

    navigationScroll();
    window.addEventListener('scroll', navigationScroll);
    return () => {
      window.removeEventListener('scroll', navigationScroll);
    };
  }, []);

  return (
    <div className={`navigation ${transparency}`}>
      <nav className="nav">
        <ul className="items text">
          <li className="item">
            <Link
              className={`link ${pathname === '/artists' ? 'active' : ''}`}
              to={pathname === '/artists' ? '/' : '/artists'}
            >
              Artists
            </Link>
          </li>
          <li className="item">
            <Link
              className={`link ${pathname === '/info' ? 'active' : ''}`}
              to={pathname === '/info' ? '/' : '/info'}
            >
              Info
            </Link>
          </li>
          <li className="item">
            <Link
              className={`link ${pathname === '/bag' ? 'active' : ''}`}
              to={pathname === '/bag' ? '/' : '/bag'}
            >
              Bag[{state.order.length}]
            </Link>
          </li>
        </ul>
      </nav>
      {pathname === '/info' ||
      pathname === '/bag' ||
      pathname === '/artists' ? (
        <div className="nav-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      ) : (
        <div
          className="nav-back text"
          style={pathname === '/' ? { display: 'none' } : {}}
          onClick={() => navigate(-1)}
        >
          <span>close[x]</span>
        </div>
      )}
    </div>
  );
}
